import logo from './logo.svg';
import './App.css';
import { useState, useContext, useEffect } from 'react';
import { HashRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import HomePageAR from './pages/HomePageAR';
import Nav from './components/Nav';
import AboutMe from './components/AboutMe';
import Part from './components/Part';
import Experience from './pages/Experience';
import ExperienceAR from './pages/ExperienceAR';
import Portfolio from './pages/Portfolio';
import ScrollToTop from './components/ScrollToTop';
import Contact from './pages/Contact';
import Certifications from './pages/Certifications';
import PortfolioAR from './pages/PortfolioAR';
import ContactAR from './pages/ContactAR';
import CertificationsAR from './pages/CertificationsAR';
import React from 'react';
import BlogPost from './components/BlogPost';

export function useWindowWidth() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1080);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1080);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isSmallScreen;
}

export const ScreenSizeContext = React.createContext();

export function ScreenSizeProvider({ children }) {
  const isSmallScreen = useWindowWidth();

  return (
    <ScreenSizeContext.Provider value={isSmallScreen}>
      {children}
    </ScreenSizeContext.Provider>
  );
}


function App() {
  return (
    <ScreenSizeProvider>
    <Router basename={process.env.PUBLIC_URL}>
      <ScrollToTop />
      <Routes>
        <Route element={<HomePage/>} path='/'/>
        <Route element={<Portfolio/>} path='/portfolio'/>
        <Route element={<Experience/>} path='/experience'/>
        <Route element={<Contact/>} path='/contact'/>
        <Route element={<Certifications/>} path='/certifications'/>
        <Route element={<CertificationsAR/>} path='/certifications-ar'/>
        <Route element={<HomePageAR/>} path='/ar'/>
        <Route element={<PortfolioAR/>} path='/portfolio-ar'/>
        <Route element={<ExperienceAR/>} path='/experience-ar'/>
        <Route element={<ContactAR/>} path='/contact-ar'/>
        <Route element={<BlogPost/>} path='/test'/>
      </Routes>
    </Router>
    </ScreenSizeProvider>
  );
}

export default App;
