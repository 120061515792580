import TagsAR from '../components/TagsAR';
import CompAR from '../components/CompAR';

export default function FooterAR(){
    return(
        <footer className='bg-gray-800 pr-1 sm:pr-5 flex py-6 sm:py-9' dir="rtl">
          <div>
            <TagsAR>
              <p className='mb-2 sm:mb-0'>footer</p>
              <CompAR text={'برامجي'} path={'/portfolio-ar'} comment={'/* مشاريعي السابقة (ويب) */'} />
              <CompAR text={'تجاربي'} path={'/experience-ar'} comment={'/* تجاربي (التدريس أساسًا) */'} />
            </TagsAR>
          </div>
        </footer>
    )
}