import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { ScreenSizeContext } from '../App';

const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  right: ${({ isOpen }) => (isOpen ? '0' : '-250px')};
  width: 250px;
  height: 100%;
  background-color: #fff;
  color: #265FC3;
  transition: right 0.3s ease-in-out;
  z-index: 1000;
`;

const SidebarToggle = styled.div`
  position: fixed;
  top: 15px;
  right: 15px;
  background-color: transparent;
  color: ${({isOpen})=> (isOpen ? '#265FC3' : 'white')};
  padding: 10px;
  cursor: pointer;
  border-radius: 10%;
  font-size: 2.5rem;
  z-index: 1001;
  transition: background-color 0.3s ease;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 999;
`;

const SidebarContent = styled.ul`
  padding: 20px;
  list-style: none;
  margin-top: 4rem;
  font-size: 1.6rem;
  font-family: rubik;
`;

const SidebarItem = styled.li`
  margin: 20px 0;
`;

function SidebarAR () {
  const location = useLocation();  
  let engLocation = location.pathname;
  if (engLocation.includes("-")){
    engLocation = engLocation.replace("-ar", "");
  } else {
    engLocation = location.pathname.replace("ar", "");
  }
  

  const [isOpen, setIsOpen] = useState(false);
  const isSmallScreen = useContext(ScreenSizeContext);
  const navigate = useNavigate();
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <SidebarToggle isOpen={isOpen} onClick={toggleSidebar}>
        {isSmallScreen && <div className='text-[2rem] -mr-[1rem]'>☰</div>}
        {!isSmallScreen && <div className='-mr-[1rem] sm:mr-0'>☰</div>}
      </SidebarToggle>
      <Overlay isOpen={isOpen} onClick={toggleSidebar} />
      <SidebarContainer isOpen={isOpen} dir="rtl">
        <SidebarContent>
          <SidebarItem><p className=' hover:cursor-pointer hover:underline' onClick={()=>{navigate('/ar')}}>الرئيسية</p></SidebarItem>
          <SidebarItem><p className=' hover:cursor-pointer hover:underline' onClick={()=>{navigate('/portfolio-ar')}}>ملف الأعمال</p></SidebarItem>
          <SidebarItem><p className=' hover:cursor-pointer hover:underline' onClick={()=>{navigate('/experience-ar')}}>الخبرة</p></SidebarItem>
          <SidebarItem><p className=' hover:cursor-pointer hover:underline' onClick={()=>{navigate('/certifications-ar')}}>الشهادات</p></SidebarItem>
          <SidebarItem><p className=' hover:cursor-pointer mt-10 hover:underline' onClick={()=>{navigate('/contact-ar')}}>تواصل معي</p></SidebarItem>
          <SidebarItem><p className=' hover:cursor-pointer mt-[10rem] hover:underline' onClick={()=>{navigate(engLocation)}}>EN</p></SidebarItem>

        </SidebarContent>
      </SidebarContainer>
    </>
  );
}

export default SidebarAR;
