import Typewriter from 'typewriter-effect';

export default function TerminalAR({ title, text }) {
  return (
    <div className="w-[50rem] h-[35rem]  rounded-lg bg-black m-[3rem] shadow-black shadow-md">
      <div className="h-full w-full rounded-lg border-black bg-black subpixel-antialiased shadow-2xl">
        <div className="flex h-6 items-center rounded-t-lg border-b border-gray-500 bg-gray-100 text-center text-black" id="headerTerminal">
          <div className="mr-2 flex h-3 w-3 items-center rounded-full border-red-900 bg-red-500 text-center shadow-inner" id="closebtn"></div>
          <div className="mr-2 h-3 w-3 rounded-full border-yellow-900 bg-yellow-500 shadow-inner" id="minbtn"></div>
          <div className="mr-2 h-3 w-3 rounded-full border-green-900 bg-green-500 shadow-inner" id="maxbtn"></div>
          <div className="mx-auto pl-16" id="terminaltitle">
            <p className="text-center text-sm">{title}</p>
          </div>
        </div>
        <div className="h-auto bg-black sm:pr-1 pt-1 font-mono text-xs text-green-200" id="console">
          <p style={{ fontFamily: 'monospace' }} className="p-10 text-[1.5rem] m-2 leading-normal">
            ROBCO INDUSTRIES UNIFIED OPERATING SYSTEM<br />
            COPYRIGHT 2075-2077 ROBCO INDUSTRIES<br />
            -Server 1-<br /><br />
            <span dir="rtl" style={{ fontFamily: 'Cairo' }}>
            <Typewriter
  onInit={(typewriter) => {
    typewriter.typeString(text)
      .pauseFor(2500)
      .start();
  }}
  options={{
    delay: 50, // Adjust this value to control the typing speed
  }}
/>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
