import { useNavigate } from "react-router-dom"
export default function Comp ({text, path, comment}) {
    const navigate = useNavigate();
    return (
        <>
        <h1 className=' hover:cursor-pointer  hover:scale-105 items-center duration-200 ease-in-out text-[1.2rem] md:text-[2rem] flex sm:ml-10 text-[#4DC9B0]' onClick={()=>{navigate(path)}} style={{fontFamily: 'monospace'}}>
        <span className="whitespace-nowrap">
        <span className=' text-[#808080]'>&lt;</span>
        {text}
        <span className=' text-[#808080] '>/&gt; </span>
        </span>
        <p className="ml-1 md:ml-7 text-[1rem] md:text-[2rem] text-[#6a9956]"> 
        <span className="text-[#569cd6] ">&#123;</span> 
        {comment}
        <span className="text-[#569cd6]">&#125;</span>
        </p>
        </h1>
        </>
    )
}