import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { ScreenSizeContext } from '../App';

const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? '0' : '-250px')};
  width: 250px;
  height: 100%;
  background-color: #fff;
  color: #265FC3;
  transition: left 0.3s ease-in-out;
  z-index: 1000;
`;

const SidebarToggle = styled.div`
  position: fixed;
  top: 15px;
  left: 15px;
  background-color: transparent;
  color: ${({isOpen})=> (isOpen ? '#265FC3' : 'white')};
  padding: 10px;
  cursor: pointer;
  border-radius: 10%;
  font-size: 2.5rem;
  z-index: 1001;
  transition: background-color 0.3s ease;
  
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 999;
`;

const SidebarContent = styled.ul`
  padding: 20px;
  list-style: none;
  margin-top: 4rem;
  font-size: 1.6rem;
  font-family: lato;
`;

const SidebarItem = styled.li`
  margin: 20px 0;
`;



function Sidebar () {
  const [isOpen, setIsOpen] = useState(false);
  const isSmallScreen = useContext(ScreenSizeContext);
  const location = useLocation();
  const arLocation = location.pathname === "/" ? location.pathname + "ar" : location.pathname + "-ar"
  const navigate = useNavigate();
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <SidebarToggle isOpen={isOpen} onClick={toggleSidebar}>
        {isSmallScreen && <div className='text-[2rem] -ml-[1rem]'>☰</div>}
        {!isSmallScreen && <div>☰</div>}
      </SidebarToggle>
      <Overlay isOpen={isOpen} onClick={toggleSidebar} />
      <SidebarContainer isOpen={isOpen}>
        <SidebarContent>
          <SidebarItem><p className=' hover:cursor-pointer hover:underline' onClick={()=>{navigate('/')}}>Home</p></SidebarItem>
          <SidebarItem><p className=' hover:cursor-pointer hover:underline' onClick={()=>{navigate('/portfolio')}}>Portfolio</p></SidebarItem>
          <SidebarItem><p className=' hover:cursor-pointer hover:underline' onClick={()=>{navigate('/experience')}}>Experience</p></SidebarItem>
          <SidebarItem><p className=' hover:cursor-pointer hover:underline' onClick={()=>{navigate('/certifications')}}>Certifications</p></SidebarItem>
          
          <SidebarItem><p className=' hover:cursor-pointer mt-10 hover:underline' onClick={()=>{navigate('/contact')}}>Contact me</p></SidebarItem>
          <SidebarItem><p style={{fontFamily: 'rubik'}} className=' hover:cursor-pointer mt-[10rem] hover:underline' onClick={()=>{navigate(arLocation)}}>العربية</p></SidebarItem>


        </SidebarContent>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
