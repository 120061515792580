import SidebarAR from "../components/SideAR";
import { useNavigate } from "react-router-dom";

export default function PortfolioAR() {
  const navigate = useNavigate();
  return (
    <>
      <SidebarAR />
      <div className="text-center justify-center items-center text-white space-y-8 font-bold font-cairo my-[7rem] text-[3rem] sm:text-[4rem]" dir="rtl">
        <h1>برامجي/مواقعي</h1>
        <p className="font-rubik self-center text-[1.5rem] sm:text-[2rem] mx-11 sm:mx-0">
          هذه هي عرض شامل لما قمت به حتى الآن من حيث المشاريع الإلكترونية
        </p>
        <button onClick={() => { navigate('/contact-ar') }} className="font-rubik text-[1.4rem] bg-white px-6 rounded sm:px-5 py-2 text-[#265FC3] active:scale-95 ease-in-out duration-100 shadow-lg">
          احصل على موقعك/تطبيقك الآن
        </button>
      </div>
      <div className="flex justify-center items-center py-7">
        <iframe title="Jabel Net" src="https://www.jabelnet.com" className="mx-4 rounded" width={1280} height={768} />
      </div>
      <div className="flex justify-center items-center py-7">
        <iframe title="Basirah" src="https://basirah.org/" className="mx-4 rounded" width={1280} height={768} />
      </div>
      <div className="flex justify-center items-center py-7">
        <iframe title="Easy Grade" src="https://easygrade.me/" className="mx-4 rounded" width={1280} height={768} />
      </div>
      <div className="flex justify-center items-center py-7">
        <iframe title="Snippet Rock Paper Scissors" src="https://asusbit.github.io/rock-paper-scissors/" className="mx-4 rounded" width={1280} height={768} />
      </div>
    </>
  )
}
