import { useNavigate } from "react-router-dom"

export default function CompAR ({text, path, comment}) {
    const navigate = useNavigate();
    return (
        <>
        <h1 className=' hover:cursor-pointer font-roboto items-center  hover:scale-105 duration-200 ease-in-out text-[1.6rem] sm:text-[2rem] flex sm:mr-10 text-[#4DC9B0]' onClick={()=>{navigate(path)}} dir="rtl">
        <span className=' text-[#808080]'>&lt;</span>
        {text}
        <span className=' text-[#808080]'>/&gt;</span>
        <p className="mr-2 sm:mr-7 text-[1.3rem] sm:text-[2rem] text-[#6a9956]"> 
        <span className="text-[#569cd6] ">&#123;</span> 
        {comment}
        <span className="text-[#569cd6]">&#125;</span>
        </p>
        </h1>
        </>
    )
}
