import CertBox from "../components/CertBox"
import Injaz from '../certifications/Injaz.png'
import Engineering from '../certifications/Engineering.png'
import Python from '../certifications/Python.png'
import Snippet from '../certifications/snippet-web.jpg'
import Sidebar from "../components/Side"
import { animate, motion } from "framer-motion"
import Footer from "../components/Footer"
export default function Certifications (){
    const certifications = [Injaz, Engineering, Python, Snippet];
    return (<>
    <Sidebar/>
    <p>
        <h1 className="font-didot text-white text-center text-[2.5rem] sm:text-[4rem] my-[8rem]">Certifications<br/><span className=" font-lato text-[1.5rem] line-clamp-2 sm:text-[2rem]">a small list of <span className=" text-green-400">relevant </span>certifications I have received over the years. </span></h1>

    </p>
        <div className='flex flex-wrap'>
        {certifications.map((cert, index) => {
            return (<motion.div whileHover={{
                scale: 1.05,
                transition: { duration: .3 },
            }} className="sm:w-1/2">
            
            <CertBox key={index} image={cert}/>
            
            </motion.div>)
        })}
        </div>

        
    <Footer/>
        
        </>
    )
}