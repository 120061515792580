export default function Tags({children}){
    return (
        <div style={{fontFamily: 'monospace'}} className=" text-[1.3rem] sm:text-[2rem] text-[#569cd6]">
            <div className=" flex ">
    <span className=' text-[#808080]'>&lt;</span>
    {children[0]}
    <span className=' text-[#808080]'>&gt;</span>
    </div>
    {children.slice(1)}
    <div className=" flex">
    <span className=' text-[#808080]'>&lt;/</span>
    {children[0]}
    <span className=' text-[#808080]'>&gt;</span></div>
    </div>
)
}