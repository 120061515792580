import { useForm, ValidationError } from '@formspree/react';
import { useNavigate } from 'react-router-dom';
import SidebarAR from '../components/SideAR';
import { ScreenSizeContext, ScreenSizeProvider } from '../App';
import { useContext } from 'react';

export default function ContactAR() {
  const [state, handleSubmit] = useForm("xzzpowqq");
  const navigate = useNavigate();
  const isSmallScreen = useContext(ScreenSizeContext);
  const cols = isSmallScreen ? 28 : 33;
  
  if (state.succeeded) {
    return (
      <div className='text-white m-10 sm:m-0 min-h-screen' dir="rtl">
        <div className='grid justify-center items-center min-h-screen'>
          <div className='grid justify-center items-center'>
            <p className='text-[4rem] font-cairo'>شكرًا!</p>
            <p className='text-[2rem] font-rubik'>سأحاول الرد عليك في أقرب وقت ممكن...</p>
            <button onClick={() => { navigate('/') }} className="font-rubik text-[1.7rem] mt-7 bg-white rounded px-5 py-2 text-[#265FC3] active:scale-95 ease-in-out duration-100 shadow-lg">
              الرئيسية
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='h-screen mx-[3rem] sm:px-[4rem] mt-[6rem]' dir="rtl">
        <SidebarAR />
        <p className='text-white text-[3rem] sm:text-[4rem] font-cairo'>
          مرحبا!
        </p>
        <p className='text-white text-[1.5rem] sm:text-[2rem] font-rubik pb-[3rem]'>
          إذا كنت ترغب في التعاون، يرجى إعلامي بفكرتك وبريدك الإلكتروني هنا حتى نتمكن من التواصل!<br />{' ('}يمكنك أيضًا التواصل معي عبر أي من حساباتي الاجتماعية في <span className='text-[#4DC9B0] hover:underline hover:cursor-pointer' onClick={() => { navigate('/') }}>الصفحة الرئيسية</span>{')'}
        </p>
        <form onSubmit={handleSubmit} className='text-white text-[1.1rem] sm:text-[1.5rem] space-y-4 grid font-rubik'>
          <label htmlFor="email" className='grid sm:w-[25rem]'>
            <input className='text-black py-1 rounded-sm'
              id="email"
              type="email"
              name="email"
              placeholder=' بريدك الإلكتروني...'
            />
          </label>
          <ValidationError
            prefix="البريد الإلكتروني"
            field="email"
            errors={state.errors}
          />
          <label>
            <textarea className='text-black rounded-sm'
              id="message"
              name="message"
              placeholder=" ماذا لديك في ذهنك؟"
              cols={cols}
              rows={4}
            />
          </label>
          <ValidationError
            prefix="الرسالة"
            field="message"
            errors={state.errors}
          />
          <button type="submit" disabled={state.submitting} className='bg-white text-[#265FC3] shadow-lg sm:w-[25rem] h-[3rem] rounded active:scale-95 ease-in-out duration-100 mb-10'>
            إرسال
          </button>
        </form>
    </div>
  );
}
