import Sidebar from "../components/Side";
import { useNavigate } from "react-router-dom";

export default function Portfolio (){
    const navigate = useNavigate();
    return (
        <>
        <Sidebar/>
        <div className="text-center justify-center items-center text-white space-y-8 font-bold font-didot my-[7rem] text-[3rem] sm:text-[4rem]">
            <h1>My portfolio</h1>
            <p className=" font-lato  self-center text-[1.5rem] sm:text-[2rem] mx-11 sm:mx-0">
                this is a complete showcase of what I have done so far in terms of web projects
            </p>
            <button onClick={()=>{navigate('/contact')}} className=" font-lato text-[1.4rem] bg-white mx-6 rounded sm:px-5 py-2 text-[#265FC3] active:scale-95 ease-in-out duration-100  shadow-lg ">
            Get your own quality website/application
            </button>
        </div>
        <div className=" flex justify-center items-center py-7">

        <iframe title="Jabel Net" src="https://www.jabelnet.com" className="mx-4 rounded" width={1280} height={768}/>
        
        </div>
        <div className=" flex justify-center items-center py-7">

        <iframe title="basirah" src="https://basirah.org/basirahEN.php" className="mx-4 rounded" width={1280} height={768}/>
        
        </div>

        <div className=" flex justify-center items-center py-7">

        <iframe title="easy grade" src="https://easygrade.me/" className="mx-4 rounded" width={1280} height={768}/>
        
        </div>

        <div className=" flex justify-center items-center py-7">

        <iframe title="snippet rock paper scissors" src="https://asusbit.github.io/rock-paper-scissors/" className="mx-4 rounded" width={1280} height={768}/>

        </div>

        
        </>
    )
}