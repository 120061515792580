import React from 'react';
import { useContext } from 'react';
import SmallScreenContext from '../App';
import { easeIn, easeInOut, motion, transform } from 'framer-motion';
import { useInView } from 'react-intersection-observer';


export default function Part({ bg, logo, wid, title, text, textcol }) {
  const isSmallScreen = useContext(SmallScreenContext);
  
  const { ref, inView } = useInView({
    triggerOnce: false, 
    threshold: 0.5,// Change this to false if you want the animation to trigger again whenever it comes in view
  });

  const variants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.9,
        easeInOut
      },
    },
  };

  return (
    <div ref={ref} className="py-[16rem] items-center justify-center " style={{ backgroundColor: bg, color: textcol }}>
      <motion.div variants={variants} initial="hidden" animate={inView ? "show" : "hidden"}>
      <div className=" p-4  flex items-center justify-center">
                <img src={logo} style={{width: wid }}className='  '/>
            </div>
            <div className=' items-center flex justify-center  w-full'>
            <div className=' items-center justify-center  w-[90%] sm:w-1/2'>
            
            <p className=' text-center font-lato text-[1.3rem] sm:text-[1.7rem]  '>
                <span className=' font-didot text-[2rem] sm:text-[2.7rem]'>
                {title} {isSmallScreen && <><br/></>}
                </span>
                {text}
                </p>
                
                </div>
                </div></motion.div>
    </div>
  );
}
