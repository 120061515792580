import CertBox from "../components/CertBox";
import Injaz from '../certifications/Injaz.png';
import Engineering from '../certifications/Engineering.png';
import Python from '../certifications/Python.png';
import Snippet from '../certifications/snippet-web.jpg';
import SidebarAR from "../components/SideAR";
import FooterAR from "../components/FooterAR";
import { motion } from "framer-motion";

export default function CertificationsAR() {
    const certifications = [Injaz, Engineering, Python, Snippet];
    return (
        <>
            <SidebarAR/>
            <div dir="rtl">
                <h1 className="font-cairo text-white text-center text-[2.5rem] sm:text-[4rem] my-[8rem]">
                    الشهادات<br/>
                    <span className="font-rubik text-[1.5rem] line-clamp-2 sm:text-[2rem]">
                        قائمة صغيرة من <span className="text-green-400">الشهادات ذات الصلة </span> التي حصلت عليها على مر السنين.
                    </span>
                </h1>
                <div className='flex flex-wrap'>
                    {certifications.map((cert, index) => {
                        return (
                            <motion.div whileHover={{
                                scale: 1.05,
                                transition: { duration: .3 },
                            }} className="sm:w-1/2" key={index}>
                                <CertBox image={cert}/>
                            </motion.div>
                        );
                    })}
                </div>
                <FooterAR/>
            </div>
        </>
    );
}
