import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AboutMe from "../components/AboutMe";
import Nav from "../components/Nav"
import Part from "../components/Part"
import Start from "../components/Start"
import myPic from '../svg/MyPic.svg'
import ReactFullpage from "@fullpage/react-fullpage"
import Side from '../components/Side';
import NavLeft from '../components/NavLeft';
import Sidebar from '../components/Side';
import logo from '../logo.svg';
import Typewriter from 'typewriter-effect';
import Comp from '../components/Comp';
import Terminal from '../components/Terminal';
import Divider from '../components/Divider';
import Tags from '../components/Tags';
import asm from '../images/asm.png';
import cheat from '../images/term.png'
import rps from '../images/rps.png'
import github from '../images/github-pages.png'
import {ScreenSizeContext} from '../App'
import { animate, motion, useScroll, useTransform } from 'framer-motion';
import Card from '../components/Card';
import Footer from '../components/Footer';

export default function HomePage(){
    const [collapsed, setCollapsed] = useState(true);
    const navigate = useNavigate();
    const isSmallScreen = useContext(ScreenSizeContext);
    const {scrollYProgress} = useScroll();
    const scale = useTransform(scrollYProgress, [0, 1], [0.2, 2]);
    const titles = ["Terminal Cheat Sheet", "ARM Assembly compiler script", "Rock Paper Scissors", "React / GH-pages"]
    const texts = ["Terminal-based cheat sheet for quick, local command reference, no internet", "One-step ARM assembly compiler script for automated linking and compiling", "Rock Paper Scissors game built in React for interactive play.", "Tutorial on hosting React projects for free with GitHub Pages, including custom domain setup."]
    const paths = ["https://github.com/AsusBit/terminal-cheat-sheet", "https://github.com/AsusBit/arm-assembly-compile-script", "https://github.com/AsusBit/rock-paper-scissors", "https://www.linkedin.com/posts/hamzah-alnofli-31a637226_publishing-a-react-website-on-github-pages-activity-7235666771414814720-dbRB?utm_source=share&utm_medium=member_ios"]
    const images = [cheat, asm, rps, github]
    const [screenSize, setScreenSize] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
          setScreenSize(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
        
      }, []);
    

    return(
        <div className=' overflow-clip'>
       <Sidebar/>
        <h1 className="sm:flex">

        
        <Start/>

        {!isSmallScreen && <motion.div style={{width: screenSize / 2}} className=' cursor-grab active:cursor-grabbing flex justify-center items-center m-3  w-[40rem] card:w-[45rem]'  drag={true} dragConstraints={{left:-10, right: 10, top: -10, bottom: 10}}><Terminal title={'Resume.exe'} text={"Welcome to my portfolio page, where I showcase my projects and experiences with the aim of securing freelance opportunities or a full-time position. Please feel free to explore and enjoy your visit."}/></motion.div>}

        </h1>


        <section>
        <AboutMe/>
        </section>

        <section>
        <h1 className=' font-didot mt-[4rem] text-white text-[4rem] ml-[2rem] sm:ml-[5rem]'>
            Blog
        </h1>
        
        <motion.div  className=' mx-2 py-20 pb-24 grid card:grid-cols-3 grid-rows-1 gap-[3rem] card:gap-[6rem] justify-center items-center card:justify-between'>
        {titles.map((title, index)=><Card width={screenSize < 1178 ? '100%' : '100%'} title={title} image={images[index]} text={texts[index]} path={paths[index]} key={index}/>)}    
        
        </motion.div>
        </section>
        
        
            <Footer/>
        </div>
    )
}