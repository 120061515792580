import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AboutMeAR from "../components/AboutMeAR";
import Nav from "../components/Nav"
import PartAR from "../components/PartAR"
import StartAR from "../components/StartAR"
import myPic from '../svg/MyPic.svg'
import ReactFullpage from "@fullpage/react-fullpage"
import Side from '../components/Side';
import NavLeft from '../components/NavLeft';
import SidebarAR from '../components/SideAR';
import logo from '../logo.svg';
import Typewriter from 'typewriter-effect';
import CompAR from '../components/CompAR';
import TerminalAR from '../components/TerminalAR';
import TagsAR from '../components/TagsAR';
import { ScreenSizeContext } from '../App';
import { motion } from 'framer-motion';
import CardAR from '../components/CardAR';
import asm from '../images/asm.png';
import cheat from '../images/term.png'
import rps from '../images/rps.png'
import github from '../images/github-pages.png'
import FooterAR from '../components/FooterAR';

export default function HomePageAR() {
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const isSmallScreen = useContext(ScreenSizeContext);
  const titles = [
    "دليل Terminal",
    "سكربت تجميع تجميعي ARM",
    "حجر ورقة مقص",
    "React / GitHub Pages" ];
  
  const texts = [
    "دليل Terminal يعمل ضمن التيرمينال للمرجعية السريعة والمحلية للأوامر، دون الحاجة إلى الإنترنت",
    "سكربت تجميع لنسخة ARM من لغة أسمبلي خطوة واحدة للربط والتجميع",
    "لعبة حجر ورقة مقص مبنية باستخدام React للعب التفاعلي",
    " دليل حول وضع مشاريع React في الانترنت مجانًا باستخدام GitHub Pages، بالاضافة الى عنوان خاص"
  ];
  
  const paths = ["https://github.com/AsusBit/terminal-cheat-sheet", "https://github.com/AsusBit/arm-assembly-compile-script", "https://github.com/AsusBit/rock-paper-scissors", "https://www.linkedin.com/posts/hamzah-alnofli-31a637226_publishing-a-react-website-on-github-pages-activity-7235666771414814720-dbRB?utm_source=share&utm_medium=member_ios"]
  const images = [cheat, asm, rps, github]
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
      const handleResize = () => {
        setScreenSize(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
      
    }, []);
  
  return (
    <>
      
        <SidebarAR />
        <h1 className="sm:flex mb-[12rem]" dir="rtl">
          <StartAR />
          {!isSmallScreen && <motion.div style={{width: Math.round(screenSize / 1.5)}} className=' cursor-grab active:cursor-grabbing flex justify-center items-center mx-3  w-[40rem] card:w-[45rem]'  drag={true} dragConstraints={{left:-10, right: 10, top: -10, bottom: 10}}><TerminalAR title={'Resume.exe'} text={"مرحبًا بكم في صفحتي الشخصية! هنا أشارك مشاريعي وخبراتي، على أمل أن أنال إعجابكم وأحصل على فرصة عمل أو عمل حر. أتمنى لكم جولة ممتعة!"} /></motion.div>}
          
        </h1>
        

        <section>
        <AboutMeAR width={screenSize} />
        </section>

        <section>
        <h1 style={{direction: 'rtl'}} className=' font-cairo mt-16 text-white text-[4rem] mr-[2rem] sm:mr-[5rem]'>
            المدونة
        </h1>
        <motion.div  className=' mx-2 card:ml-[4rem] py-20 pb-24 grid card:grid-cols-3 grid-rows-1 gap-[6rem] card:gap-[6rem] justify-center items-center card:justify-between'>
        {titles.map((title, index)=><CardAR width={screenSize < 1178 ? '100%' : '80%'} title={title} image={images[index]} text={texts[index]} path={paths[index]} key={index}/>)}    
        
        </motion.div>
        </section>

       
        <FooterAR/>
    </>
  )
}
