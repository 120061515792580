import { useForm, ValidationError } from '@formspree/react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Side';
import { ScreenSizeProvider } from '../App';
import { useContext } from 'react';
export default function Contact() {
  const [state, handleSubmit] = useForm("xzzpowqq");
  const navigate = useNavigate();
  const isSmallScreen = useContext(ScreenSizeProvider);
  const cols = isSmallScreen ? 50 : 33;
  if (state.succeeded) {
      return (<div className=' text-white m-10 sm:m-0 min-h-screen '>
        <div className=' grid justify-center items-center min-h-screen'>
        <div className='grid justify-center items-center '>
      <p className=' text-[4rem] font-didot'>Thanks!</p>
      <p className=' text-[2rem] font-lato'>I will try to reply to you as soon as I can...</p>
      <button onClick={()=>{navigate('/')}} className=" font-lato text-[1.7rem]  mt-7 bg-white rounded px-5 py-2 text-[#265FC3] active:scale-95 ease-in-out duration-100  shadow-lg ">
        Home
      </button>
      </div>
      </div>
      </div>);
  }
  return (
    <div className='h-screen mx-[3rem] sm:px-[4rem] mt-[6rem] '>
        <ScreenSizeProvider>

        <Sidebar/>
        <p className=' text-white text-[3rem] sm:text-[4rem] font-didot'>
            Welcome!
        </p>
        <p className=' text-white text-[1.5rem] sm:text-[2rem] font-lato pb-[3rem]'>
            if you want to work together, let me know your idea & email in here so we can get in contact!<br/> {'('}you can also contact me using any of my socials in the <span className=' text-[#4DC9B0] hover:underline hover:cursor-pointer' onClick={()=>{navigate('/')}}>home page</span>{')'}
        </p>
    <form onSubmit={handleSubmit} className='text-white text-[1.1rem] sm:text-[1.5rem] space-y-4 grid font-lato'>
      <label htmlFor="email" className=' grid sm:w-[25rem] '>
        
      
      <input className='text-black py-1 rounded-sm'
        id="email"
        type="email" 
        name="email"
        placeholder=' your email...'
      />
      </label>
      <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
      <label>
        
      <textarea className='text-black rounded-sm'
        id="message"
        name="message"
        placeholder=" what's on your mind?"
        cols={cols}
        rows={4}
      /></label>
      <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />
      <button type="submit" disabled={state.submitting} className='bg-white text-[#265FC3] shadow-lg sm:w-[25rem] h-[3rem] rounded active:scale-95 ease-in-out duration-100 mb-10'>
        Submit
      </button>
    </form>
    </ScreenSizeProvider>
    </div>
  );
}