import linkedin from '../svg/linkedin2.svg'
import github from '../svg/github.svg'
import email from '../svg/email.svg'
import { useContext } from 'react'
import { ScreenSizeContext } from '../App'

export default function Start(){
    const sub = 'text-white font-lato text-[2rem]'
    const isSmallScreen = useContext(ScreenSizeContext);
    return(
        <div className="m-[3rem] mt-[6rem] ml-[3rem] sm:m-[6rem]">
            <p className={sub}>
                Hi, my name is
            </p>
            <p className=" text-white font-didot text-[5rem] mt-4">
                Hamzah <br/>AlNofli <br/>
            </p>
            <p className={sub}>
           Front-end developer and tutor
            </p>
            <div className='flex space-x-4 mt-2'>
  <a href="https://www.linkedin.com/in/hamzah-alnofli-31a637226" target="_blank" rel="noopener noreferrer">
    <img src={linkedin} className='-mt-1 w-[78px] h-[78px] hover:scale-125 ease-in-out duration-200 hover:cursor-pointer' />
  </a>
  <a href="https://github.com/asusbit" target="_blank" rel="noopener noreferrer">
    <img src={github} className='mt-1 w-[64px] h-[64px] hover:scale-125 ease-in-out duration-200 hover:cursor-pointer'/>
  </a>
  <a href="mailto:hamzahalnofli@protonmail.com" target="_blank" rel="noopener noreferrer">
    <img src={email} className='mt-1 w-[64px] h-[64px] hover:scale-125 ease-in-out duration-200 hover:cursor-pointer'/>
  </a>
</div>
            
        </div>
    )
}