import py from '../images/image.png';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { AiFillFacebook } from "react-icons/ai";
export default function BlogPost(){
    const [x, setX] = useState(0);
    return (
        <><motion.div animate={{x: x}} className=" w-[20rem] p-2  justify-center flex h-[30rem] rounded-lg shadow-md shadow-black bg-[#f0f0f0]">
        <div>
        <img className=' bg-black rounded' src={py}/>
        <p>Title of the blog</p>
        <p className=' text-wrap'>a small description talking about the flex wow such great project</p>
        </div>
        
    </motion.div>
   <button>
    press to animate
   </button>
   <AiFillFacebook className=' w-[30rem] h-[30rem]'/>
    </>
        
    )
}