import Tags from '../components/Tags';
import Comp from '../components/Comp';

export default function Footer(){
    return (
        <footer className=' bg-gray-800 pl-1 md:pl-5  flex  py-6 sm:py-9'>
        <div>
        <Tags>
            <p className=' mb-2 sm:mb-0'>footer</p> 
            
            <Comp text={'Portfolio'} path={'/portfolio'} comment={'/* my past projects (web) */'}/>
            <Comp text={'Experience'} path={'/experience'} comment={'/* my experiences (teaching mostly) */'}/>
        </Tags>
        </div>
        </footer>
    )
}