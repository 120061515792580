import Part from "../components/Part";
import mukhtasar from '../images/mukhlogo.png';
import youtube from '../svg/youtube.svg'
import basirah from '../images/basirah.png';
import glasses from '../images/glasses.png';
import snippet from '../images/snippet.png';
import stack from '../images/stack11.png';
import { motion, useScroll } from "framer-motion";
import Sidebar from "../components/Side";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ScreenSizeContext } from "../App";
export default function Experience(){
    const mukhtasar_text = "I launched Mukhtasar as a YouTube channel focused on Python education, which has now evolved into a platform offering private tutoring for university students. Mukhtasar aims to make programming accessible and plans to expand into other languages and frameworks while continuing to grow."
    const basirahText = ' I joined a student startup called Basirah, focused on creating innovative glasses for the blind with features like text-to-speech, face recognition, and obstacle alerts. I served as the main presenter and developed their website.'
    const snippetText = "I joined Snippet Academy as a tutor, where I teach programming, Python, and web development courses. At Snippet Academy, we are committed to providing high-quality education to help students build strong foundations in these essential skills."
    const navigate = useNavigate();
    const isSmallScreen = useContext(ScreenSizeContext);
    return(
    <>
    <Sidebar/>
    <Part logo={mukhtasar} wid={"30rem"}  sub_logo={youtube} bg="#FEED00" title="In January 2023," text = {mukhtasar_text}/>
    <Part logo={basirah} wid={"15rem"} textcol={"#143F4E"}bg='#77C8BF' title="In October 2023," text={basirahText}/>
    <Part logo={snippet} wid={"30rem"} textcol={"#fff"}bg='#3E2545' title="In June 2024," text={snippetText}/>
    <div className=" flex items-center justify-center h-[100vh]">
        <div className=" text-white font-lato text-center text-4xl absolute ">
        check out my <span onClick={()=>{navigate('/portfolio', { state: { scroll: false } })}} className=" text-[#4DC9B0] hover:underline hover:cursor-pointer duration-100 ease-in-out">portfolio</span> for web development examples  
        </div>
    </div>
    </>
    )
}