import linkedin from '../svg/linkedin2.svg'
import github from '../svg/github.svg'
import email from '../svg/email.svg'
import { useContext } from 'react'
import { ScreenSizeContext } from '../App'

export default function Start(){
    const sub = 'text-white font-rubik text-[2rem] text-right'
    const isSmallScreen = useContext(ScreenSizeContext);
    return(
        <div className="m-[3rem] mt-[6rem] mr-[3rem] " dir="rtl">
            <p className={sub}>
                مرحباً، اسمي
            </p>
            <p className=" text-white font-cairo text-[5rem] pb-4">
                حمزة <br/> النوفلي <br/>
            </p>
            <p className={sub}>
                مطور مواقع ومعلم
            </p>
            <div className='flex space-x-reverse  mt-2'>
                <a href="https://www.linkedin.com/in/hamzah-alnofli-31a637226" target="_blank" rel="noopener noreferrer">
                    <img src={linkedin} className='-mt-1 w-[78px] h-[78px] hover:scale-125 ease-in-out duration-200 hover:cursor-pointer' />
                </a>
                <a href="https://github.com/asusbit" target="_blank" rel="noopener noreferrer">
                    <img src={github} className='mt-1 w-[64px] h-[64px] hover:scale-125 ease-in-out duration-200 hover:cursor-pointer'/>
                </a>
                <a href="mailto:hamzahalnofli@protonmail.com" target="_blank" rel="noopener noreferrer">
                    <img src={email} className='mt-1 mr-4 w-[64px] h-[64px] hover:scale-125 ease-in-out duration-200 hover:cursor-pointer'/>
                </a>
            </div>
        </div>
    )
}
