import { useContext } from 'react';
import stack from '../svg/Group 9.svg'
import { ScreenSizeContext, ScreenSizeProvider } from '../App';
import { useWindowWidth } from '../pages/HomePageAR';

export default function AboutMeAR({width}){
    const isSmallScreen = useContext(ScreenSizeContext);
    return(
        
        <div className=" text-white m-[1rem] sm:m-[6rem]" dir="rtl">
            <div className=' mr-[2rem]'>
            <p className=" font-cairo text-[4rem]">
                من أكون
            </p>
            <p className=" font-rubik text-[1.6rem] sm:w-[30rem]"> 
            <span className=" font-cairo text-[2.9rem]">أنا </span> مبرمج مواقع متخصص في تطوير الواجهات الأمامية.
            بدأت تعلم البرمجة في سن مبكرة، وأصبحت أعمل في مشاريع متعددة.<br/>
            لدي شغف بتعلم التقنيات الجديدة وتطوير المشاريع التي تساهم في المجتمع.
            <br/>
            </p>
            </div>
            
            <div className=' sm:flex items-center justify-center mt-[2rem]'>
                {isSmallScreen ? (<p style={{fontSize: width/20 }} className=' font-rubik text-[1.6rem] text-[#4DC9B0]'>مهاراتي:</p>) : (<p className=' font-rubik mx-4 sm:text-[1.8rem]'>مهاراتي | </p>)}

                <img src={stack} className={`w-[95%] lg:w-[55%] ${isSmallScreen ? 'max-h-[300px]' : 'max-h-[550px]'}`} />
            </div>
        </div>
       
    )
}
