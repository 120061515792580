import React from "react";

const Card = ({image, width, title="Card title", text="4 popular types of cards in UI design.", path}) => {
  return (
    <a target="_blank" href={path}>
    <div  title="Click to learn more" style={{width: width}} className="w-[15rem] sm:w-[19rem] hover:cursor-pointer  sm:h-[25rem] bg-gray-800 rounded-3xl text-neutral-300 p-4 flex flex-col items-start justify-center gap-3 hover:bg-gray-900 hover:shadow-2xl hover:shadow-sky-400 transition-shadow">
      {image ? <div className=" w-full flex items-center justify-center"><img src={image} className=" max-h-[12.5rem]  rounded-2xl"/></div> : <div className="w-full h-40 bg-sky-300 rounded-2xl" />}
      <div className=" text-[1.25rem]">
        <p className="font-extrabold">{title}</p>
        <p className="">{text}</p>
      </div>
      
    </div>
    </a>
  );
};

export default Card;

