import PartAR from "../components/PartAR";
import mukhtasar from '../images/mukhlogo.png';
import youtube from '../svg/youtube.svg';
import basirah from '../images/basirah.png';
import glasses from '../images/glasses.png';
import snippet from '../images/snippet.png';
import stack from '../images/stack11.png';
import { motion, useScroll } from "framer-motion";
import SidebarAR from "../components/SideAR";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ScreenSizeContext } from "../App";

export default function ExperienceAR() {
    const mukhtasarText = "أنشأت مختصر كقناة على يوتيوب تركز على تعليم بايثون والتي تطورت الآن إلى منصة تقدم دروسًا خصوصية لطلاب الجامعات. يهدف مختصر إلى جعل البرمجة في متناول الجميع ويخطط للتوسع إلى لغات وأطر أخرى مع الاستمرار في النمو.";
    const basirahText = "انضممت إلى شركة ناشئة طلابية تدعى بصيرة، والتي تركز على إنشاء نظارات مبتكرة للمكفوفين مع ميزات مثل تحويل النص إلى كلام، والتعرف على الوجوه، وتنبيهات العوائق. كنت المقدم الرئيسي وطورت موقعهم الإلكتروني.";
    const snippetText = "انضممت إلى أكاديمية سنيبت كمدرس، حيث أدرس البرمجة بايثون ودورات تطوير الويب. في أكاديمية ستيبت، نلتزم بتقديم تعليم عالي الجودة لمساعدة الطلاب على بناء أساسيات قوية في هذه المهارات الأساسية.";
    const navigate = useNavigate();
    const isSmallScreen = useContext(ScreenSizeContext);

    return (
        <>
                <SidebarAR />
                <PartAR 
                    logo={mukhtasar} 
                    wid={"30rem"}  
                    sub_logo={youtube} 
                    bg="#FEED00" 
                    title="في يناير ٢٠٢٣" 
                    text={mukhtasarText}
                    textDirection="rtl"
                />
                <PartAR 
                    logo={basirah} 
                    wid={"15rem"} 
                    textcol={"#143F4E"} 
                    bg='#77C8BF' 
                    title="في أكتوبر 2023،" 
                    text={basirahText}
                    textDirection="rtl"
                />
                <PartAR 
                    logo={snippet} 
                    wid={"30rem"} 
                    textcol={"#fff"} 
                    bg='#3E2545' 
                    title="في يونيو 2024،" 
                    text={snippetText}
                    textDirection="rtl"
                />
                <div className="flex items-center justify-center h-[100vh]">
                    <div className="text-white font-cairo text-center text-4xl absolute" dir="rtl">
                        القِ نظرة على <span onClick={() => { navigate('/portfolio-ar', { state: { scroll: false } }) }} className="text-[#4DC9B0] hover:underline hover:cursor-pointer duration-100 ease-in-out">مواقعي</span> لأمثلة تطوير الويب
                    </div>
                </div>
        </>
    );
}
