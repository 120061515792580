import { useContext } from 'react';
import stack from '../svg/Group 9.svg'
import { ScreenSizeContext } from '../App';
export default function AboutMe(){
    const isSmallScreen = useContext(ScreenSizeContext);
    return(
        <div className=" text-white m-[1rem] sm:m-[6rem]">
            <div className=' ml-[2rem]'>
            <p className=" font-didot text-[4rem]">
                About me
            </p>
            <p className=" font-lato text-[1.6rem] sm:w-[30rem]"> 
            <span className=" font-didot text-[2.9rem]">I </span> am a tech enthusiast & innovator with self-taught expertise in React, Python, and front-end dev. Founder of Mukhtasar YouTube channel (teaching Python) and experience leading website projects.
            </p>
            </div>
            <div className=" lg:flex text-[1.5rem] md:text-[2rem] items-center mt-10">
                <p>
                    Tech stack {isSmallScreen && ":"}
                </p> 
                {!isSmallScreen && <p className='sm:text-[4rem] sm:mx-[3rem]'>
                    |
                </p>}
                
                <img src={stack} className='mt-3'/>
            </div>
        </div>
    )
}